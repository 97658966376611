.form_wrapper {
  text-align: center;

  .class407408 {
    .form_logo {
      padding: 60px 60px 40px !important;
    }

    p {
      max-width: 360px;
      margin: 0 auto;
      font-size: 20px;
      line-height: 1.5;
      color: $primary-purple;
      margin-bottom: 40px;
    }

    small {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      color: $primary-purple;
    }

    .white_button {
      border-color: $primary-purple;
    }

    .white_button:hover {
      border-color: $hover-blue;
    }

    .el-button {
      min-width: 180px;
      max-width: 250px;
      line-height: 1.5;
      display: block;
      margin: 0 auto;
      color: #fff;

      &.purple_button {
        margin-bottom: 27px;
      }

      &.reset_button {
        margin-top: 8px;
      }
    }
  }

  .default {
    padding: 121px 68px;
    color: $primary-purple;

    .title {
      font-size: 30px;
      line-height: 40px;
    }

    .main {
      font-size: 20px;
      line-height: 30px;
      margin: 30px 0;
    }

    .code {
      font-size: 16px;
      line-height: 30px;
    }
  }
}

@media (max-width: 550px) {
  .form_wrapper {
    .class407408 p {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.88;
    }

    .default {
      padding: 135px 24px 117px;

      .title {
        font-size: 20px;
      }

      .main {
        font-size: 16px;
      }

      .code {
        font-size: 14px;
      }
    }
  }
}